export const USER: string = "User";

export const TOKEN: string = "Token";
export const FINGERPRINT: string = "Fingerprint";
export const SIGNATURE: string = "Signature";
export const ENTITY: string = "Entity";
export const APPLICATION: string = "Application";

export const OBJECT: string = "Object";
export const ALLOWED_APP: string = "App";
export const ALLOWED_MENUS: string = "Menus";
export const ALLOWED_FORMS: string = "Forms";
export const ALLOWED_OPTIONS: string = "Options";
export const SELECTED_APP: string = "SelectedApp";
export const SELECTED_MENU: string = "SelectedMenu";
export const SELECTED_FORM: string = "SelectedForm";
export const EXPIRED_DIALOG_OPENED: string = "Opened";
export const SELECTED_PAYMENT_RECEIPT = "p@ym3nt-r3c31pt-p@r@m";
export const SELECTED_OPENING_MODEL = "0p3n1ng-p@r@m";
export const PARAMETERS = "Parameters";
export const CASH_REGISTER = "CashRegister";
export const USER_INFO = "UserInfo";

export const ORDER_MODEL_URL = "order_model_url";
export const ORDER_MODEL_PROCESS_TYPE = "order_model_type";

export const NON_DEVELOPED_FUNCTIONALITY = "Funcionalidad no implementada";

export const REG_INT = /^\d+$/;
export const REG_DOUBLE = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
export const REG_DOUBLE_TWO_DECIMALS = /^\d+(\.\d{1,2})?$/;
export const PATTERN_PASSWORD =
	"^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,20}$";

export const ExpiredSession = {
	yes: 1,
	no: 0
};

export const TEMP_STORE = "STORE001";

export const TABLE_PAGE_SIZES: number[] = [10, 20, 50];
// export const TABLE_PAGE_SIZES: number[] = [50, 50, 50];
// export const TABLE_PAGE_SIZES: number[] = [5, 7, 10];
